import React, { useState } from 'react'
import Img from "gatsby-image"
import BackgroundImage from 'gatsby-background-image'
import htmlDecode from "html-entities-decoder"

import "./Main.css";


function Main(props) {
    const data = props;

    return (
        // <div style={{ backgroundColor: "#051c2c" }}>
        //     <div className=" hidden md:block">
        //         <BackgroundImage
        //             Tag="section"
        //             className="background-csp-container"
        //             fluid={data.backImg.imageFile.childImageSharp.fluid}
        //         >
        //             <div className="flex container m-auto flex-wrap py-8">
        //                 <div className="w-full md:w-1/2 cs-content-container" dangerouslySetInnerHTML={{ __html: data.content }}></div>
        //             </div>
        //         </BackgroundImage>
        //     </div>
        //     <div className="block md:hidden background-csp-container">
        //         <div className="flex m-auto flex-wrap">
        //             <div className="w-full cs-content-container" dangerouslySetInnerHTML={{ __html: data.content }}></div>
        //         </div>
        //     </div>

        // </div>

        <div className="about-container">
            <div className="container m-auto py-16 flex flex-wrap text-white">
                <div className="w-full lg:w-1/2 text-center ab-left-tab px-4 md:px-0">
                    <h1 className="text-4xl text-left">
                        {htmlDecode(data.title)}
                    </h1>
                    <div dangerouslySetInnerHTML={{ __html: data.content }} className="text-xl pt-8 text-left">
                        {data.paragraph}
                    </div>
                </div>
                <div className="w-full mt-12 lg:mt-0 lg:w-1/2">
                    <Img fluid={data.backImg.imageFile.childImageSharp.fluid} alt={data.backImg.altText} className="m-auto" />
                </div>
            </div>
        </div>
    )
}

export default Main;