import React, { useState } from 'react'
import Img from "gatsby-image"
import BackgroundImage from 'gatsby-background-image'
import "./Main.css";


function Main(props) {
    const data = props;
    return (
        <div className="flex flex-wrap">
            <BackgroundImage
                Tag="section"
                className=" w-full md:w-1/2 office-map order-2 md:order-1"
                fluid={data.mapImg.imageFile.childImageSharp.fluid}
            >
                <a href={data.contactTemplate.address.addressGoogleLink} ariaLabel="Google Map" rel="noopener" className="w-full h-full block" target="_blank"></a>
            </BackgroundImage>
            <div className="w-full md:w-1/2 flex justify-center items-center py-20 px-4 md:px-0 md:py-40  order-1 md:order-2">
                <div>
                    <div>
                        <h2 className="text-3xl">
                            {data.contactTemplate.informationTitle}
                        </h2>
                        <div className="contact-infos text-xl pt-4">
                            <div>
                                <a href={`tel:${data.contactTemplate.telephoneNumber}`}>
                                    {data.contactTemplate.telephoneNumber}
                                </a>
                            </div>
                            <div>
                                <a href={`mailto:${data.contactTemplate.supportEmail}`}>
                                    {data.contactTemplate.supportEmail}
                                </a>
                            </div>
                            <div>
                                <a href={data.contactTemplate.address.addressGoogleLink} rel="noopener" target="_blank">
                                    {data.contactTemplate.address.addressLabel}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="pt-6">
                        <h2 className="text-3xl">
                            Αποστολή Βιογραφικού
                        </h2>
                        <div className="contact-infos text-xl pt-4">
                            <div>
                                <a href={`mailto:hr@enimeris.com`}>
                                    hr@enimeris.com
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="pt-6">
                        <h2 className="text-3xl">
                            {data.contactTemplate.hoursTitle}
                        </h2>
                        <div className="contact-infos text-xl pt-4">
                            <span>
                                {data.contactTemplate.hoursLabel}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Main;