import React, { useState } from 'react'
import Img from "gatsby-image"
import { Link } from 'gatsby'
import Home from "./../../images/home.svg"


import "./Breadcrumb.css";


function Breadcrumb(props) {
    const data = props;
    console.log(data)
    return (
        <div className="breadcrumb-container" style={{ backgroundColor: "#051c2c" }}>
            <div className="container m-auto py-6 text-white flex items-center px-4">
                {data.list && data.list.map((dest, i) => {
                    if (i < data.list.length - 1) {
                        if( i === 0){
                            return (
                                <React.Fragment>
                                    <Link to={ dest.url } key={`breadcrumb-${i}`} className="cursor-pointer">
                                        <img src={Home} alt="home-icon" style={{width: "18px"}} />
                                    </Link>
                                    <span className="px-2">
                                        |
                                    </span>
                                </React.Fragment>
                            )
                        }else{
                            return (
                                <React.Fragment>
                                    <Link to={ dest.url } key={`breadcrumb-${i}`} className="cursor-pointer flex md:hidden">
                                        { dest.text.substring(0,8) + '...'}
                                    </Link>
                                    <Link to={ dest.url } key={`breadcrumb-${i}`} className="cursor-pointer hidden md:flex">
                                        { dest.text }
                                    </Link>
                                    <span className="px-2">
                                        |
                                    </span>
                                </React.Fragment>
                            )
                        }
                    } else {
                        return (
                            <span key={`breadcrumb-${i}`} >
                                { dest.text }
                            </span>
                        )
                    }
                })}
            </div>
        </div>
    )
}

export default Breadcrumb;